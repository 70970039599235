import { useDeviceSize } from "../../utils/screen_size_hook.tsx";

export default function Icon({
    src
}) {
    const deviceSize = useDeviceSize();
    let iconSize = "24px";

    if (deviceSize === 0) {
        iconSize = "14px";
    } else if (deviceSize === 1) {
        iconSize = "20px";
    }

    return <img
        src={src}
        alt=""
        width={iconSize}
        height={iconSize}
    />;
}