import { AppColors } from "../../utils/app_colors.ts";
import { useState } from "react";

export default function TertiaryButton({
    label,
    cursor,
    padding,
    margin,
    color,
    hoverColor,
    fontSize,
    fontWeight,
    onClick,
}) {
    const [isHovering, setHover] = useState(false);

    return (
        <button style={{
            cursor: cursor,
            padding: padding,
            border: "none",
            margin: margin,
            backgroundColor: AppColors.PrimaryWhite,
            fontSize: fontSize,
            fontWeight: fontWeight,
            letterSpacing: "1.5px",
            color: isHovering ? hoverColor : color,
            transition: "all .2s ease",
            WebkitTransition: "all .2s ease",
            MozTransition: "all .2s ease",
            justifySelf: "center",
            display: "flex",
            textDecoration: "none",
        }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
        >
            {label}
        </button>
    );
}

TertiaryButton.defaultProps = {
    cursor: "pointer",
    textDecoration: 'none',
    padding: "12px",
    margin: "0 auto",
    color: AppColors.PeachAccent,
    hoverColor: AppColors.PeachAccent,
    fontSize: "14px",
    fontWeight: "500",
}