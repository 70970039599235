import { AppColors } from "../../utils/app_colors.ts";

export default class TextStyle {
    public readonly fontSize: number;
    public readonly fontWeight: string;
    public readonly lineHeight: number;
    public readonly letterSpacing: number;
    public readonly fontColor: string;

    static readonly Headline1 = new TextStyle(
        96,
        "300",
        1,
        -1.5,
        AppColors.DarkGrey,
    );

    static readonly Headline2 = new TextStyle(
        60,
        "300",
        1,
        -0.5,
        AppColors.DarkGrey,
    );

    static readonly Headline3 = new TextStyle(
        48,
        "400",
        1,
        1,
        AppColors.DarkGrey,
    );

    static readonly Headline4 = new TextStyle(
        34,
        "400",
        1,
        .25,
        AppColors.DarkGrey,
    );
    
    static readonly Headline5 = new TextStyle(
        24,
        "400",
        1,
        1,
        AppColors.DarkGrey,
    );

    static readonly Headline6 = new TextStyle(
        20,
        "500",
        1,
        .15,
        AppColors.DarkGrey,
    );

    static readonly Subtitle1 = new TextStyle(
        16,
        "400",
        1,
        .15,
        AppColors.DarkGrey,
    );

    static readonly Subtitle2 = new TextStyle(
        14,
        "500",
        1,
        .1,
        AppColors.DarkGrey,
    );

    static readonly Body1 = new TextStyle(
        16,
        "400",
        1,
        .5,
        AppColors.DarkGrey,
    );

    static readonly Body2 = new TextStyle(
        14,
        "400",
        1,
        .25,
        AppColors.DarkGrey,
    );

    static readonly Button = new TextStyle(
        14,
        "500",
        1,
        1.25,
        AppColors.DarkGrey,
    );

    static readonly Caption = new TextStyle(
        12,
        "400",
        1,
        .4,
        AppColors.DarkGrey,
    );

    static readonly Overline = new TextStyle(
        10,
        "400",
        1,
        1.5,
        AppColors.DarkGrey,
    );

    private constructor(
        fontSize: number,
        fontWeight: string,
        lineHeight: number,
        letterSpacing: number,
        fontColor: string,
    ) {
        this.fontSize = fontSize;
        this.fontWeight = fontWeight;
        this.lineHeight = lineHeight;
        this.letterSpacing = letterSpacing;
        this.fontColor = fontColor;
    }
}