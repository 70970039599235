
export default function Row({
    children,
    flex,
    justifyContent,
    gap,
    padding,
}) {
    return <div
        style={{
            display: "flex",
            flex: flex,
            justifyContent: justifyContent,
            gap: gap,
            padding: padding,
        }}
    >
        {children}
    </div>;
}

Row.defaultProps = {
    flex: "none",
    justifyContent: "flex-start",
    gap: "0px",
    padding: "0px",
};
