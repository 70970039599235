import { AppColors } from "../../utils/app_colors.ts"

export default function Divider({
    color,
    height,
    width,
    margin,
}) {
    return <hr
        style={{
            borderColor: color,
            backgroundColor: color,
            color: color,
            height: height,
            border: 0,
            width: width,
            margin: margin+ " auto",
        }}
    />
}

Divider.defaultProps = {
    color: AppColors.SecondaryDarkPurple,
    height: "1px",
    margin: "8px"
}