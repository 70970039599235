
export default function Image({
    src,
    borderRadius,
    opacity,
    width,
    height,
    objectFit,
    position,
}) {
    return <img
        style={{
            borderRadius: borderRadius,
            opacity: opacity,
            transition: "all .3s ease",
            WebkitTransition: "all .3s ease",
            MozTransition: "all .3s ease",
            objectFit: objectFit,
            position: position,
            width: width,
            height: height,
        }}
        src={src}
        alt=""
    />
}

Image.defaultProps = {
    borderRadius: "8px",
    opacity: 1,
    objectFit: "cover",
}