import Layout from "../ds/organisms/layout.jsx";
import ProductCarousel from '../ds/organisms/product_carousel.jsx';
// import { useNavigate } from "react-router-dom";
import { AppColors } from '../utils/app_colors.ts';
import { ProductEntity } from '../entities/product_entity.ts'

import cookie_cat from '../assets/product_images/cookie_cat.JPG';
import cookie_cat_close from '../assets/product_images/cookie_cat_close.JPG';
import sea_party from '../assets/product_images/sea_party.JPG';
import sea_party_close from '../assets/product_images/sea_party_close.JPG';
import strawberry_fields from '../assets/product_images/strawberry_fields.JPG';
import strawberry_fields_close from '../assets/product_images/strawberry_fields_close.JPG';
import ghibli from '../assets/product_images/ghibli.JPG';
import ghibli_close from '../assets/product_images/ghibli_close.JPG';
import cosmic_cats from '../assets/product_images/cosmic_cats.JPG';
import cosmic_cats_close from '../assets/product_images/cosmic_cats_close.JPG';
import bottom from '../assets/images/bottom_banner.webp';
// import SecondaryButton from '../ds/atoms/secondary_button.jsx'
import { Banner } from "../ds/atoms/banner.jsx";
import Text from '../ds/atoms/text.jsx';
import TextStyle from "../ds/common/text_styles.ts";
import Center from "../ds/common/center.jsx";
import { useDeviceSize } from "../utils/screen_size_hook.tsx";

var productList = [
    new ProductEntity(
        {
            "id": "1",
            "title": "Cookie Cat sticker set",
            "price": 35,
            "coverImage": cookie_cat,
            "secondaryImage": cookie_cat_close,
            "feature": "Best seller"
        },
    ),
    new ProductEntity(
        {
            "id": "2",
            "title": "Sea Party sticker set",
            "price": 35,
            "coverImage": sea_party,
            "secondaryImage": sea_party_close,
        },
    ),
    new ProductEntity(
        {
            "id": "3",
            "title": "Strawberry Fields sticker set",
            "price": 35,
            "coverImage": strawberry_fields,
            "secondaryImage": strawberry_fields_close,
        },
    ),
    new ProductEntity(
        {
            "id": "4",
            "title": "Ghibli sticker set",
            "price": 35,
            "coverImage": ghibli,
            "secondaryImage": ghibli_close,
        },
    ),
    new ProductEntity(
        {
            "id": "5",
            "title": "Cosmic Cats sticker set",
            "price": 35,
            "coverImage": cosmic_cats,
            "secondaryImage": cosmic_cats_close,
        },
    ),
];

export default function Home() {
    // const navigate = useNavigate();

    const deviceSize = useDeviceSize();

    let newArrivalsStyle = TextStyle.Headline2;
    let viewFullRangeStyle = TextStyle.Headline6;
    let ourStoryStyle = TextStyle.Subtitle1;
    let ourStoryPadding = "0px 28vw";
    let newArrivalsPadding = "0px 0px 20px 0px";

    if (deviceSize === 0) {
        newArrivalsStyle = TextStyle.Headline4;
        viewFullRangeStyle = TextStyle.Body2;
        ourStoryStyle = TextStyle.Overline;
        ourStoryPadding = "0px 10vw";
        newArrivalsPadding = "0px 0px 12px 0px";
    } else if (deviceSize === 1) {
        ourStoryPadding = "0px 10vw";
    }


    return (
        <>
            <Layout>
                <Center>
                    <Text
                        style={newArrivalsStyle}
                        color={AppColors.PrimaryLightPurple}
                        fontWeight="600"
                        padding={newArrivalsPadding}
                    >
                        New arrivals!
                    </Text>
                </Center>
                <Text
                    style={viewFullRangeStyle}
                    color={AppColors.PeachAccent}
                    fontWeight="500"
                    padding="0px 0px 20px 0px"
                >
                    View the full range below
                </Text>
                <Text
                    style={ourStoryStyle}
                    padding={ourStoryPadding}
                    lineHeight={1.85}
                    fontWeight={"200"}
                    color={AppColors.SecondaryDarkPurple}
                    letterSpacing={.1}
                >
                    At PeachyTea, we are all about the small details that make life more colourful.
                    We pride ourselves in the uniqueness of our products. Each item in our collection
                    is hand-crafted, making it that much more special. Whether you are looking for cute,
                    whimsical designs or something more classic, we have something for you. Come and
                    explore our store today and add some cuteness to your day.
                </Text>
                {/* <SecondaryButton
                    label="View all our products"
                    marginTop="16px"
                    onClick={
                        () => navigate("/shop")
                    }
                /> */}
                <ProductCarousel
                    productsList={productList}
                    margin="0px, 4px"
                />
                <Banner
                    src={bottom}
                    position="relative"
                    width="100%"
                    opacity=".7"
                />
            </Layout>
        </>
    );
}