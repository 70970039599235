import { useLocation, useNavigate } from 'react-router-dom'
import { AppColors } from '../../utils/app_colors.ts';
import Text from '../atoms/text.jsx';
import TertiaryButton from '../atoms/tertiary_button.jsx';
import TextStyle from '../common/text_styles.ts';
import Row from '../common/row.jsx';
import { useDeviceSize } from '../../utils/screen_size_hook.tsx';

export default function AppBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const deviceSize = useDeviceSize();

    let titleFontSize = 24;
    let ctaFontSize = 22;
    let padding = 24;
    let appBarHeight = 72;

    if (deviceSize === 0) {
        padding = 16;
        titleFontSize = 18;
        ctaFontSize = 14;
        appBarHeight = 56;
    }

    return (
        <>
            <div style={{
                position: "fixed",
                background: "white",
                zIndex: "100000000000",
                boxShadow: "0px -5px 8px 1px " + AppColors.DarkGrey,
                justifyContent: "space-between",
                display: "flex",
                justifySelf: "stretch",
                alignItems: "center",
                width: "100vw",
                padding: "0px " + padding + "px",
                boxSizing: "border-box",
                height: appBarHeight + "px"
            }}>
                <Text
                    style={TextStyle.Headline5}
                    color={AppColors.SecondaryDarkPurple}
                    fontWeight='500'
                    fontSize={titleFontSize + "px"}
                >
                    PeachyTea
                </Text>
                <Row
                    gap="12px"
                >
                    <TertiaryButton
                        label="Home"
                        fontSize={ctaFontSize + "px"}
                        color={location.pathname === "/" ? AppColors.SecondaryDarkPurple : AppColors.BeigeAccent}
                        hoverColor={AppColors.SecondaryDarkPurple}
                        padding="0px"
                        onClick={() => navigate("/")}
                    />
                    <TertiaryButton
                        label="Shop"
                        fontSize={ctaFontSize + "px"}
                        color={location.pathname === "/shop" ? AppColors.SecondaryDarkPurple : AppColors.BeigeAccent}
                        hoverColor={AppColors.SecondaryDarkPurple}
                        padding="0px"
                        onClick={() => navigate("/shop")}
                    />
                </Row>
            </div >
        </>
    );
}
