class AppColors {
    public static get PrimaryLightPurple() : string {
        return "#996FD8";
    }

    public static get SecondaryDarkPurple() : string {
        return "#401F71";
    }

    public static get BeigeAccent() : string {
        return "#FFDEB4";
    }

    public static get DarkGrey() : string {
        return "#141414";
    }

    public static get PeachAccent() : string {
        return "#FFB4B4";
    }

    public static get PrimaryWhite() : string {
        return "#FFFFFF";
    }
    
    public static get MediumGrey() : string {
        return "#343534";
    }
}

export { AppColors } 