import AppBar from "../molecules/app_bar.jsx";
import { Banner } from "../atoms/banner.jsx";
import headerBanner from '../../assets/images/header_banner.webp';
import { useDeviceSize } from "../../utils/screen_size_hook.tsx";
import InstagramLink from "../molecules/instagram_link.jsx";

export default function Layout(props) {
    const deviceSize = useDeviceSize();

    let bannerPadding = "72px 0px 32px 0px";

    if (deviceSize === 0) {
        bannerPadding = "56px 0px 20px 0px";
    }

    return (
        <>
            <AppBar />
            <Banner
                src={headerBanner}
                width="100%"
                margin="auto"
                objectFit="cover"
                padding={bannerPadding}
            />
            {props.children}
            <InstagramLink />
        </>
    );
}