import React from "react";

export default function Text({
    children,
    style,
    fontSize,
    fontWeight,
    lineHeight,
    letterSpacing,
    color,
    align,
    padding,
    margin,
    decoration,
}) {
    return <h1
        style={{
            color: color ?? style.fontColor,
            fontSize: fontSize ?? style.fontSize,
            fontWeight: fontWeight ?? style.fontWeight,
            lineHeight: lineHeight ?? style.lineHeight,
            letterSpacing: letterSpacing ?? style.letterSpacing,
            textAlign: align,
            padding: padding,
            margin: margin,
            textDecoration: decoration,
        }}>
        {children}
    </h1>
}

Text.defaultProps = {
    align: "center",
    padding: "0px",
    margin: "0px",
    decoration: "none",
}