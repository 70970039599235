export function Banner({
    src,
    width,
    margin,
    objectFit,
    objectPosition,
    padding,
    opacity
}) {
    return (
        <img
            style={{
                width: width,
                margin: margin,
                objectFit: objectFit,
                objectPosition: objectPosition,
                padding: padding,
                opacity: opacity,
            }}
            src={src}
            alt=""
        />
    )
}

Banner.defaultProps = {
    width: "100%",
    margin: "auto",
    objectFit: "cover",
    opacity: 1,
}