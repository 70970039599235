import Layout from "../ds/organisms/layout.jsx";
import Text from '../ds/atoms/text.jsx';
import TextStyle from "../ds/common/text_styles.ts";
import { AppColors } from "../utils/app_colors.ts";

export default function Shop() {
    return (
        <>
            <Layout>
                <Text
                    style={TextStyle.Headline2}
                    color={AppColors.PrimaryLightPurple}
                    fontWeight={"500"}
                >
                    Coming soon
                </Text>
            </Layout >
        </>
    );
}