import React from "react";

export default function Center(
    props,
) {
    return <div
        style={{
            display: "flex",
            justifyContent: "center",
        }}
    >
        {props.children}
    </div>;
}