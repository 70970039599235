import ProductCard from './product_card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDeviceSize } from "../../utils/screen_size_hook.tsx";

export default function ProductCarousel({
    productsList,
    margin,
}) {
    const deviceSize = useDeviceSize();
    let cardHeight = "600px";

    if (deviceSize === 0) {
        cardHeight = "60vh";
    } else if (deviceSize === 1) {
        cardHeight = "40vh";
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1080 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1080, min: 760 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 760, min: 0 },
            items: 1
        }
    };

    return (
        <div
            style={{
                margin: margin,
            }}
        >
            <Carousel
                responsive={responsive}
                renderButtonGroupOutside={true}
                infinite={true}
            >
                {
                    productsList.map((item, i) => <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "60px"
                        }}
                    >
                        <ProductCard
                            product={item}
                            width="100%"
                            height={cardHeight}
                        />
                    </div>
                    )
                }
            </Carousel>
        </div>
    );
}

ProductCarousel.defaultProps = {
    margin: "none",
}