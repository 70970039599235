import { useState } from "react";
import Image from "../atoms/image";
import Text from '../atoms/text.jsx';
import Divider from "../atoms/divider";
import TextStyle from "../common/text_styles.ts";
import { useDeviceSize } from "../../utils/screen_size_hook.tsx";
import { AppColors } from "../../utils/app_colors.ts";

export default function ProductCard({
    product,
    width,
    height,
}) {
    const priceString = "R" + product.price;
    const [isHovering, setHover] = useState(false);
    const deviceSize = useDeviceSize();

    let textStyle = TextStyle.Body2;
    let textTopPadding = "16px 0px 0px 0px";
    let dividerMargin = "8px";

    if (deviceSize === 0) {
        textTopPadding = "8px 0px 0px 0px"
        textStyle = TextStyle.Overline;
        dividerMargin = "6px";
    } else if (deviceSize === 1) {
        textTopPadding = "12px 0px 0px 0px"
    }



    return <div
        style={{
            width: width,
        }}
    >
        <div
            style={{
                height: height,
                position: "relative"
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onTouchStart={() => setHover(!isHovering)}
        >
            <Image
                src={product.secondaryImage}
                width={width}
                height={height}
                position="absolute"
            />
            <Image
                src={product.coverImage}
                opacity={isHovering ? 0 : 1}
                width={width}
                height={height}
                position="absolute"
            />
        </div>
        <Text
            style={textStyle}
            padding={textTopPadding}
            color={AppColors.PrimaryLightPurple}
        >
            {product.title}
        </Text>
        <Divider
            width="20px"
            color={AppColors.SecondaryDarkPurple}
            margin={dividerMargin}
        />
        <Text
            style={textStyle}
            color={AppColors.PrimaryLightPurple}
        >
            {priceString}
        </Text>
    </div>
}