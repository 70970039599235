import { useState, useLayoutEffect } from "react";

enum DeviceSize {
    mobile,
    tablet,
    desktop,
}

export function useDeviceSize() {
    var deviceSize;
    if (window.innerWidth < 760) {
        deviceSize = DeviceSize.mobile;
    } else if (window.innerWidth < 1080) {
        deviceSize = DeviceSize.tablet;
    } else {
        deviceSize = DeviceSize.desktop;
    }
    const [size, setSize] = useState(deviceSize);

    useLayoutEffect(() => {
        function updateSize() {
            var deviceSize;
            if (window.innerWidth < 760) {
                deviceSize = DeviceSize.mobile;
            } else if (window.innerWidth < 1000) {
                deviceSize = DeviceSize.tablet;
            } else {
                deviceSize = DeviceSize.desktop;
            }
            setSize(deviceSize);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [],
    );

    return size;
}