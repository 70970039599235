
class ProductEntity {
    id: string;
    title: string;
    price: number;
    coverImage: string;
    secondaryImage: string;
    feature: string;

    constructor(json) {
        this.id = json.id;
        this.title = json.title;
        this.price = json.price;
        this.coverImage = json.coverImage;
        this.secondaryImage = json.secondaryImage;
        this.feature= json.feature;
    }

}

export { ProductEntity }