import Row from "../common/row";
import TextStyle from "../common/text_styles.ts";
import Text from "../atoms/text.jsx";
import { AppColors } from "../../utils/app_colors.ts";
import instagramIcon from '../../assets/icons/instagram_icon.svg';
import { useDeviceSize } from "../../utils/screen_size_hook.tsx";
import Icon from "../atoms/icon.jsx";

export default function InstagramLink() {
    const deviceSize = useDeviceSize();

    let textStyle = TextStyle.Headline6;
    let textPadding = "0px 10px 0px 0px";

    if (deviceSize === 0) {
        textStyle = TextStyle.Caption;
        textPadding = "0px 6px 0px 0px";

    } else if (deviceSize === 1) {
        textStyle = TextStyle.Body1;
    }

    return <a
        href="https://www.instagram.com/peachyteastore/"
        target="_blank"
        rel="noreferrer"
    >
        <Row
            justifyContent="center"
            padding="24px 0px"
        >
            <Text
                style={textStyle}
                decoration="underline"
                color={AppColors.PeachAccent}
                padding={textPadding}
            >
                Check out our instagram
            </Text>
            <Icon
                src={instagramIcon}
            />
        </Row>
    </a>
}